import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { FormControl } from "react-bootstrap";

const MapboxAutocomplete = ({
  onSelect,
  debounceDelay = 500,
  optionValue,
  setOptionValue,
  onClear,
  setDeliveryLocationName,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const mapboxToken = process.env.REACT_APP_MAPBOXTOKEN;
  // Fetch suggestions from Mapbox API when inputValue changes
  useEffect(() => {
    if (inputValue.length < 3) {
      setOptions([]);
      return;
    }

    setLoading(true);
    const timer = setTimeout(() => {
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          inputValue
        )}.json?access_token=${mapboxToken}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data && data.features) {
            setOptions(data.features);
          }
        })
        .catch((err) => console.error("Error fetching geocode data", err))
        .finally(() => setLoading(false));
    }, debounceDelay);

    return () => clearTimeout(timer);
  }, [inputValue, mapboxToken, debounceDelay]);

  return (
    <Autocomplete
      freeSolo
      sx={{
        "& .MuiInputBase-root": {
          color: "var(--textColor)", // Changes input text color
        },
        "& .MuiInputLabel-root": {
          color: "var(--textColor)", // Changes label color
        },
      }}
      inputValue={inputValue}
      options={options}
      getOptionLabel={(option) => option.place_name || ""}
      filterOptions={(x) => x}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === "clear") {
          setInputValue("");
          if (typeof onClear === "function") {
            onClear();
          }
        } else {
          setInputValue(() => newInputValue);
        }
      }}
      placeholder="Search for a location"
      value={optionValue}
      onChange={(event, value) => {
        setDeliveryLocationName(value.place_name);
        setOptionValue(value);
        if (value) {
          onSelect(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          style={{
            color: "rgb(189, 196, 224)",
            margin: "0",
            padding: "5px 5px 5px 10px",
            fontSize: "14px",
          }}
          placeholder="Search for a location"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default MapboxAutocomplete;
