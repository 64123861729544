import { useEffect, useState } from "react";
import { TbEdit, TbSignRight } from "react-icons/tb";
import { MdDone } from "react-icons/md";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationModel from "./ConfirmationModel";
import { startMission, updateMission, stopMission } from "../API";
import AddMissionsModal from "./AddMissionModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "./Loader";

export default function MissionCard({ index, mission, locationList }) {
  const [editable, setEditable] = useState(false);
  const [missionLoader, setMisisonLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showStopBtn, setShowStopBtn] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [fleetId, setFleetId] = useState(
    window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    )
  );
  const [fleetData, setFleetData] = useState();
  const { fleetList: fleetDataList } = useSelector((state) => state.fleetList);
  const [robots, setRobots] = useState([]);
  const [availableRobots, setAvailableRobots] = useState([]);
  const [showPlayBtn, setShowPlayBtn] = useState(mission?.autoStart === false && mission?.status === false);

  useEffect(() => {
      setShowPlayBtn(mission?.autoStart === false && mission?.status === false && availableRobots.length>0 && !missionLoader);
      setShowStopBtn(mission?.status === true&& !missionLoader);
  },[mission])

  useEffect(() => {
    let fleetIdFromUrl = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    if (fleetIdFromUrl) {
      setFleetId(fleetIdFromUrl);
    }
  }, []);
  useEffect(() => {
    let robotsList = fleetDataList?.find(
      (item) => item?.fleetName === fleetId
    )?.robotsDetails;
    let robotsDetailsList = fleetDataList?.find(
      (item) => item?.fleetName === fleetId
    )?.robots;
    robotsList = robotsList?.filter((item) => {
      return item?.assignedMissions?.includes(mission._id);
    });
    let availableRobotsList = robotsList?.filter((item) => {
      return robotsDetailsList?.find((robot) => robot?.robotId === item?.robotId)?.robotStatus==="AVAILABLE";
    });
    // console.log(
    //   fleetDataList.find((item) => item?.fleetName === fleetId),
    //   "mission list"
    // );

    setRobots(robotsList);
    setAvailableRobots(availableRobotsList);
    setFleetData(fleetDataList?.find((item) => item?.fleetName === fleetId));
  }, [fleetDataList, fleetId]);
  const destructureActions = (actions) => {
    let componentList = [];
    let newActions = actions;
    newActions
      // .filter((item) => item.message !== "MOVEHOME")
      .map((action, index) => {
        if (!action?.locationId) {
          return;
        }
        // let startTimeString = action.startTime.substring(0,2)+":"+action.startTime.substring(2,4);
        let startTimeString =
          index < actions.length - 1
            ? newActions[index + 1]?.startTime?.substring(0, 2) +
              ":" +
              newActions[index + 1]?.startTime?.substring(2, 4)
            : "";

        let finishTimeString =
          action?.finishTimeCalculated &&
          action?.finishTimeCalculated?.substring(0, 2) +
            ":" +
            action?.finishTimeCalculated?.substring(2, 4);

        componentList.push(
          <div className="flex flex-col justify-center items-center" key={`mission-action-${index}`}>
            <div className="bg-textColor rounded-full w-[10px] h-[10px]"></div>
            <div className="flex flex-col items-center">
              <>
                <div className="text-sm">
                  {action?.locationId?.locationName}
                </div>
                <div className="text-[12px]">{finishTimeString}</div>{" "}
              </>
              {/* {!editable ? (
                <>
                  <div className="text-sm">
                    {action?.locationId?.locationName}
                  </div>
                  <div className="text-[12px]">{finishTimeString}</div>{" "}
                </>
              ) : (
                <>
                  <div className="text-sm">
                    {action?.locationId?.locationName}
                  </div>
                  <input
                    className="px-2 text-[12px] py-1 w-fit text-center border-1 border-white rounded-md bg-[#282f42] "
                    type="time"
                    onChange={formik.handleChange}
                    value={finishTimeString}
                    name="hello"
                  />
                </>
              )} */}
            </div>
          </div>
        );

        // componentList.push(
        //   <div className="flex flex-col justify-center items-center relative">
        //         <div className="bg-white rounded-full w-[10px] h-[10px]"></div>
        //         <div className="w-[130px] flex justify-between absolute top-[-16px]">
        //           <div className="text-[12px]">{finishTimeString}</div>
        //           {(index<newActions.length-1)? <div className="text-[12px]">{startTimeString}</div>:""}
        //         </div>

        //         <div className="flex flex-col items-center">
        //           <div className="text-sm">{action?.locationId?.locationName}</div>
        //         </div>
        //   </div>)
      });
    return componentList;
  };

  const handleDelete = () => {
    mission.isDeleted = true;
    updateMission(mission, mission._id, localStorage.getItem("token"));
    // console.log(mission,'mission');
    // updateMission()
  };

  const handleStartMission = async () => {
    setShowPlayBtn(false);
    setMisisonLoader(true);
    robots?.map(async (item) => {
      try{
      const res = await startMission(mission?._id, item?.robotId, localStorage.getItem("token"));
      console.log(res,"res mission");
      if(res.status===200){
        toast.success(res.data.message);
        setMisisonLoader(false);
      }
      else{
        toast.error(res.data.message);
        setMisisonLoader(false);
        setShowPlayBtn(true);
      }
    }
      catch(error){
          toast.error(error.response.data.message);
          setMisisonLoader(false);
          setShowStopBtn(true);
      }
    })
  }

  const handleStopMission = async () => {
    setShowStopBtn(false);
    setMisisonLoader(true);
    robots?.map(async (item) => {
        try{
      const res = await stopMission(mission?._id, item?.robotId, localStorage.getItem("token"));
      console.log(res,"res mission");
      if(res.status===200){
        toast.success(res.data.message);
        setMisisonLoader(false);
      }
      else{
        toast.error(res.data.message);
        setMisisonLoader(false);
        setShowStopBtn(true);
        }
      }
      catch(error){
        toast.error(error.response.data.message);
        setMisisonLoader(false);
        setShowStopBtn(true);
      }
    })
  }


  return (
    <>
      <div className={`flex flex-col gap-3 bg-bodycolor pl-4 pr-4 pb-4 `}>
        {modalOpen && (
          <AddMissionsModal
            modalOpen={modalOpen}
            mission={mission}
            setModalOpen={setModalOpen}
            locationList={locationList}
          />
        )}
        {confirmationModalOpen && (
          <ConfirmationModel
            showActionLiveDropdown={false}
            textToDisplay={"Are you sure?"}
            onConfirm={handleDelete}
            showConfirmationModal={confirmationModalOpen}
            setShowConfirmationModal={setConfirmationModalOpen}
          />
        )}
        <div className="w-full flex flex-row justify-between items-center">
          {/* mission?.autoStart===false && mission?.status===false && */}
          <div className="flex gap-2 items-center">
            {showPlayBtn && (
              <div
                className="flex justify-center items-center"
                onClick={handleStartMission}
              >
                <img
                  className="w-8 cursor-pointer text-ottonomyBlue mt-2"
                  src={"/assets/images/play-icon1.svg"}
                />
              </div>
            )}
            {
              missionLoader && (
                <div className="flex justify-center items-center w-8 h-8">
                  <div className="w-8 lds-ripple scale-[0.5] mr-10 mt-[8px]">
                    <div></div>
                  </div>
                </div>
              )
            }
            {
              showStopBtn && (
                <div
                  className="flex justify-center items-center"
                  onClick={handleStopMission}
                >
                  <img
                    className="w-8 cursor-pointer text-ottonomyBlue mt-2"
                    src={"/assets/images/stop-icon.svg"}
                  />
                </div>
              )
            }
            <div className="font-bold">{mission.name}</div>
          </div>
          {robots && robots?.length > 0 && (
            <div className="flex flex-row gap-x-2">
              {robots?.map((item) => (
                <div className="text-textColor" key={item.robotId}>{item?.robotId}</div>
              ))}
            </div>
          )}
          {/* {!editable ? (
            <div className="font-bold">{mission.name}</div>
          ) : (
            <input
              className="px-4 py-1 w-[300px] text-center border-1 border-white rounded-md bg-[#282f42] font-bold "
              type="text"
              onChange={formik.handleChange}
              value={formik.values.missionName}
              name="missionName"
            />
          )} */}
          <div className="flex flex-row items-center">
            <div className="ml-2" onClick={() => setModalOpen(true)}>
              <TbEdit className="w-5 h-5 cursor-pointer text-ottonomyBlue" />
            </div>

            <div
              className="ml-2"
              onClick={() => setConfirmationModalOpen(true)}
            >
              <DeleteIcon className="w-5 h-5 cursor-pointer text-[#e96c67]" />
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex justify-center">
            <div className="w-full border-t !border-textColor absolute mt-1 z-0"></div>
          </div>
          <div className="flex justify-around z-1">
            {destructureActions(mission.actions)}
          </div>
        </div>
      </div>
    </>
  );
}
