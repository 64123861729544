import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useCallback } from "react";
import axios from "axios";
import { useRef } from "react";

import "react-phone-input-2/lib/bootstrap.css";
import {
  useHasPermissionInFleet,
  useHasPermissionInAnyFleet,
} from "../customHooks/PermissionHooks";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import { useLocation } from "react-router-dom";

import { storeRobotDetails } from "../redux/Actions";
import RobotCard from "./RobotCard";
import MissionCard from "./MisisonCard";
import FleetPagerobotPage from "../components/FleetPagerobotPage";
import { userActions } from "../redux/slice/userSlice";
import { MessageTwoTone } from "@mui/icons-material";
import axiosInstance from "../config/axiosConfig";
// import { ReactComponent as MessageSvg } from  'public/assets/images/message-icon.svg'
import { getAllUserGroups, getLocationListLive, getLocationsByLocationType } from "../API";
import SendCommunicationCard from "./SendCommunicationCard";
import AddMissionsModal from "./AddMissionModal";

function Missions(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const auth = queryParams.get("auth");
  const [modalOpen, setModalOpen] = useState(false);
  const hasPermissionForSendCommunication = useHasPermissionInAnyFleet("send-communication");


  const [robotData, setRobotData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();

  const audioRef = useRef(null);
  const dispatch = useDispatch();
  const incomingData = useLocation();
  const emailId = localStorage.getItem("useremail");
  const { user, sessionDestroyed } = useSelector((state) => state.login);
  const [missionData, setMissionData] = useState([])
  const [hideLogout, setHideLogout] = useState(false);
  const [hideNavigation,setHideNavigation] = useState(false);
  const [fleetData, setFleetData] = useState();
  const [fleetId,setFleetId] = useState(window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  ));

  useEffect(() => {
    if(auth){
      setHideLogout(true)
      setHideNavigation(true)
      localStorage.setItem("apiKey",auth)
    }
  })
  useEffect(() => {
    let fleetIdFromUrl = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    if(fleetIdFromUrl){ 
    setFleetId(fleetIdFromUrl)
    }
  },[]);
  const { fleetList: fleetDataList } = useSelector((state) => state.fleetList);
  // console.log(fleetDataList, 'fleetDataList');
  const [showCommunicationModal, setShowCommunicationModal] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const token = localStorage.getItem("token");
  useEffect(() => {
    setFleetData(fleetDataList.find((item) => item.fleetName === fleetId));
  },[fleetDataList, fleetId]); 

  // useEffect(() => {
    
  //   const response = getAllUserGroups({
  //     "fleetId": fleetData?.fleetId
  //   })
  //   response.then((res) => { 
  //     dispatch(userActions.setUserGroup(res));
  //   })
  // }, [fleetData,dispatch])

  useEffect(() => {
    if (!sessionDestroyed) {
      getLocationsByLocationType(
        props.fleetData ? props.fleetData._id : fleetData._id, []
      )
        .then((res) => {
          // console.log(res, 'res');
          setLocationList(res?.data?.data);
        })
        .catch((error) => {
          toast.error("Unable to fetch Location List");
        });
    }
  }, []);

  const updateState = useCallback(async () => {
    if (!sessionDestroyed && token) {
      dispatch(fleetListAndRobotStatusByUserEmail(emailId));
    }
  }, [dispatch, emailId, sessionDestroyed]);

  const useFleetIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };



  // useEffect(() => {
  //   const fleetId = props.fleetData ? props.fleetData?._id : fleetData?._id;
  //   dispatch(storeRobotDetails(fleetId));
  // }, []);




  function parseItem(item) {
    const [, stringPart = "", numberPart = 0] =
      /(^[a-zA-Z]*)(\d*)$/.exec(item) || [];
    return [stringPart, numberPart];
  }
  const updateRobotETA = useCallback(async () => {
    if (!sessionDestroyed && (token||auth)) {
      return await axiosInstance
        .post(
          `getRobotsETA/${
            props.fleetData ? props.fleetData?._id : props.fleetData?._id
          }`
        )
        .then((res) => {
          setRobotData(
            res.data.result.sort((a, b) => {
              const [stringA, numberA] = parseItem(a.robotId);
              const [stringB, numberB] = parseItem(b.robotId);
              const comparison = stringA.localeCompare(stringB);
              return comparison === 0
                ? Number(numberA) - Number(numberB)
                : comparison;
            })
          );
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
          // toast.error("Something went wrong")
        });
      // console.log("Result",res.data.result.sort((a,b) => a.robotId - b.robotId))
    }
  }, [props.fleetData, sessionDestroyed, token,auth]);
  const getMissions = useCallback(async () => {
    if (!sessionDestroyed && (token||auth)) {
      return await axiosInstance
        .post(
          `missions/getmissionbyfleetid`,{"fleetId":props.fleetData?._id}
        )
        .then((res) => {
          setMissionData(
            res.data.missions)
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
          // toast.error("Something went wrong")
        });
      // console.log("Result",res.data.result.sort((a,b) => a.robotId - b.robotId))
    }
  }, [props.fleetData, sessionDestroyed, token,auth]);
  const playSound = async () => {
    if (audioRef.current) {
      try {
        await audioRef.current.play();
      } catch (error) {
        console.error("Failed to play audio:", error);
      }
    }
    // audioRef.current.play();
  };

  const stopSound = async () => {
    if (audioRef.current) {
      try {
        await audioRef.current.pause();
      } catch (error) {
        console.error("Failed to pause audio:", error);
      }
    }
    // audioRef.current.pause();
  };


  useEffect(() => {
    // console.log(incomingData, "incomingData")
    if (
      incomingData &&
      incomingData?.state?.userData &&
      user &&
      Object.keys(user).length > 0
    ) {
      toast.success("Logged in Successfully!");
    }
  }, [user, incomingData]);




  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();
    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useFleetIntervalAsync(updateState, 5000);
  useIntervalAsync(updateRobotETA, 1000 * 2);
  useIntervalAsync(getMissions,5000);
  return (
    <>
    {modalOpen && (
          <AddMissionsModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            locationList={locationList}
          />
        )}
      <div className="grid grid-cols-8 gap-3 h-full relative">
        <audio ref={audioRef}>
          <source src="/assets/audio/alert.wav" type="audio/wav"></source>
        </audio>
        <div className="grid col-span-3 text-textColor bg-primaryColor p-3 rounded-lg shadow-md">
          <div className="relative flex flex-col gap-2 min-h-[200px] overflow-auto">
            <span className="sticky top-0 pb-2 flex items-center text-lg font-semibold font-poppins">
              Robots
            </span>

            {robotData && robotData?.length !== 0 ? (
              <>
                {robotData.map((robot, index) => (
                  <RobotCard
                    key={index}
                    index={index}
                    robot={robot}
                    selectedLocation={selectedLocation}
                    showSummonControls={false}
                    playSound={playSound}
                    stopSound={stopSound}
                  />
                ))}
              </>
            ) : (
              <div className="text-textColor font-semibold flex items-center justify-center w-full h-full ">
                {" "}
                No Robot to show
              </div>
            )}
          </div>
        </div>
        <div className="grid col-span-5 text-textColor bg-primaryColor p-3 rounded-lg shadow-md">
          <div className="relative flex flex-col gap-2 min-h-[200px] max-h-full overflow-auto">
            <div className="w-full flex flex-row justify-between">
              <span className="sticky top-0 pb-2 flex items-center text-lg font-semibold font-poppins">
                Active Missions
              </span>
              <div className="hover:cursor-pointer hover:shadow-md flex flex-row  bg-ottonomyBlue rounded-md px-2 py-0.5 justify-center items-center" onClick={() => setModalOpen(true)}>
                <div className="font-bold text-sm !text-secTextColor">Add Mission</div>
                {/* <Add /> */}
              </div>
            </div>

            {missionData && missionData?.length !== 0 ? (
              <>
                {missionData.map((mission, index) => (
                  <MissionCard
                    key={index}
                    index={index}
                    mission={mission}
                    locationList={locationList}
                  />
                ))}
              </>
            ) : (
              <div className="text-textColor font-semibold flex items-center justify-center w-full h-full ">
                {" "}
                No Missions to show
              </div>
            )}
          </div>
        </div>
        {/* <div className="grid col-span-8 text-textColor bg-primaryColor p-3 rounded-lg ">
          <div className="relative flex flex-col gap-2 min-h-[350px] max-h-[350px]">
            <span className="sticky top-0 bg-[#282f41] pb-2 flex items-center text-lg font-semibold font-poppins">
              Communication
            </span>
              <>
                <SendCommunicationCard/>
              </>
          </div>
        </div> */}
       
        <div className="grid col-span-8 text-textColor bg-primaryColor p-3 rounded-lg h-full shadow-md">
          <FleetPagerobotPage
            googlemap={true}
            fleetData={props.fleetData ? props.fleetData : fleetData}
          />
        </div>
      </div>
    </>
  );
}

export default Missions;
