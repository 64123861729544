import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useSelector } from "react-redux";
import MapboxAutocomplete from "./MapboxAutocomplete"; // import your custom autocomplete

const MapboxGeocoderComponent = (props) => {
  const [zoom, setZoom] = useState(15);
  const [, setPolygonarray] = useState([]);
  // const [marker, setMarker] = useState(null);
  // const [map, setMap] = useState(null);
  const { fleetList } = useSelector((state) => state.fleetList);
  const [geofencecoordinates] = useState(
    props?.fleetData?.map?.geofence?.map((data) => [data.lng, data.lat])
  );

  // Create a ref for the map container
  const mapContainerRef = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOXTOKEN;

    const newMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
      center: [
        props.fleetData?.map?.longitude
          ? props.fleetData?.map?.longitude
          : fleetList[0]?.map?.longitude,
        props.fleetData?.map?.latitude
          ? props.fleetData?.map?.latitude
          : fleetList[0]?.map?.latitude,
      ],
      zoom: zoom,
    });

    newMap.on("load", () => {
      newMap.addSource("geofence", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [geofencecoordinates],
          },
        },
      });
      newMap.addLayer({
        id: "geofenceoutline",
        type: "line",
        source: "geofence",
        layout: {},
        paint: {
          "line-color": "#f00",
          "line-width": 3,
        },
      });
    });

    let newpolygonarray = [];

    props?.fleetData?.map?.teleoperationZones.map((polygon, index) => {
      newpolygonarray.push(polygon.map((data) => [data.lng, data.lat]));

      newMap.on("load", () => {
        newMap.addSource("fleetzone-" + index, {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [newpolygonarray[index]],
            },
          },
        });
        newMap.addLayer({
          id: "fleetzoneoutline-" + index,
          type: "fill",
          source: "fleetzone-" + index,
          layout: {},
          paint: {
            "fill-color": "#7859bc",
            "fill-opacity": 0.75,
          },
        });
      });

      return newpolygonarray;
    });

    newMap.on("move", () => {
      setZoom(newMap.getZoom().toFixed(2));
    });

    setPolygonarray(newpolygonarray);
    props?.setMap(newMap);

    const newMarker = new mapboxgl.Marker({ draggable: true })
      .setLngLat([-79.4512, 43.6568])
      .addTo(newMap);
    
    newMap.on("click", (e) => {
      const { lng, lat } = e.lngLat;
      newMarker.setLngLat([lng, lat]);
      props.setLngLat(e.lngLat);
      props.setOptionValue("")
      props.setIsDropMarkerDraged(true)
    })
    newMarker.on("dragend", () => {
      const lngLat = newMarker.getLngLat();
      props.setOptionValue("")
      props.setLngLat(lngLat);
      props.setIsDropMarkerDraged(true)
    });

    props?.setMarker(newMarker);

    // Clean up on unmount
    return () => newMap.remove();
  }, []);

  useEffect(() => {
    if (props?.map) {
      const bounds = new mapboxgl.LngLatBounds(
        geofencecoordinates[0],
        geofencecoordinates[0]
      );
      for (const coord of geofencecoordinates) {
        bounds.extend(coord);
      }
      props?.map.fitBounds(bounds, { padding: 20 });
    }
  }, [props?.map, geofencecoordinates]);

  // Handler for when a location is selected from the autocomplete
 

  return (
  
      <div
        ref={mapContainerRef}
        id="map"
        style={{ width: "100%", height: "300px" }}
      />
  );
};

export default MapboxGeocoderComponent;
